import {lazy} from "react";

export const Signup = lazy(() => import('../auth/content/Signup'));
export const Login = lazy(() => import('../auth/content/Login'));
export const PasswordRecovery = lazy(() => import('../auth/content/PasswordRecovery'));
export const ChangePassword = lazy(() => import('../auth/content/ChangePassword'));
export const PasswordSetup = lazy(() => import('../auth/content/PasswordSetup'));
export const Report = lazy(() => import('../public/content/Report'));
export const Privacy = lazy(() => import('../public/content/Privacy'));
export const Terms = lazy(() => import('../public/content/Terms'));

export const Subscription = lazy(() => import('../public/content/Subscription'));
export const Review = lazy(() => import('../public/content/Review'));
export const Help = lazy(() => import('../public/content/Help'));

export const Dashboard = lazy(() => import('../dashboard/content/Dashboard'));

export const Profile = lazy(() => import('../profile/content/Profile'));
export const Account = lazy(() => import('../account/content/Account'));
export const Billing = lazy(() => import('../billing/content/Billing'));

export const Reservation = lazy(() => import('../reserve/content/Reservation'));
export const DealManagement = lazy(() => import('../reserve/content/DealManagement'));
export const PublicInformation = lazy(() => import('../reserve/content/PublicInformation'));
export const Gallery = lazy(() => import('../gallery/Gallery'));
